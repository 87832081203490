
  import { Component, Vue } from "vue-property-decorator";
  import { store } from "@/store";
  import { dispatchCheckLoggedIn, dispatchCheckApiError } from "@/store/main/actions";
  import { readIsLoggedIn, readToken } from "@/store/main/getters";
  import { api } from "@/api";

  const startRouteGuard = async (to, from, next) => {
    await dispatchCheckLoggedIn(store);
    if (readIsLoggedIn(store)) {
      if (to.path === "/login" || to.path === "/") {
        if ("next" in to.query && to.query["next"]) {
          const next = to.query["next"] as string;
          if (next.startsWith("https://degluted.fdi2.com")) {
            const token = readToken(store);
            try {
              const resp = await api.getThinkificSSOUrl(token, false, false, next);
              window.location.href = resp.data.url;
            } catch (error) {
              dispatchCheckApiError(store, error);
            }
          }
        }
        next("/main");
      } else {
        next();
      }
    } else if (readIsLoggedIn(store) === false) {
      if ((to.path as string).startsWith("/main")) {
        next("/login");
      } else {
        next();
      }
    }
  };

  @Component
  export default class Start extends Vue {
    public beforeRouteEnter(to, from, next) {
      startRouteGuard(to, from, next);
    }

    public beforeRouteUpdate(to, from, next) {
      startRouteGuard(to, from, next);
    }
  }
